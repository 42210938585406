import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"
import _debounce from "lodash/debounce"

// Connects to data-controller="combo-box"
export default class extends Controller {
  static targets = ["input", "list", "listItem", "userId", "email"]
  static values = { url: String }

  connect() {
    this.performSearch = _debounce(this.search, 500).bind(this)

    this.inputTarget.addEventListener("focus", () => this.showList())
  }

  search() {
    get(`${this.urlValue}?query=${this.inputTarget.value}`, {
      responseKind: "turbo-stream",
    })
  }

  selectItem(event) {
    if (this.hasInputTarget) {
      this.inputTarget.value = event.params.user.email
    }

    if (this.hasUserIdTarget) {
      this.userIdTarget.value = event.params.user.id
    }

    if (this.hasEmailTarget) {
      this.emailTarget.value = event.params.user.email
    }

    this.hideList()
  }

  showList() {
    this.listTarget.classList.remove("hidden")
  }

  hideList() {
    this.listTarget.classList.add("hidden")
  }
}
