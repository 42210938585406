import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    eventName: String,
    eventObject: String,
    currentUserId: String,
  }

  connect() {
    window.dataLayer = window.dataLayer || []

    this.push()
  }

  push() {
    window.dataLayer.push({ ecommerce: null })

    window.dataLayer.push({
      ...(this.eventName && this.eventName),
      ...(this.userId && this.userId),
      ...(this.ecommerceObject && this.ecommerceObject),
    })
  }

  get eventName() {
    if (this.hasEventNameValue && this.eventNameValue.length > 0) {
      return { event: this.eventNameValue.toString() }
    } else {
      return null
    }
  }

  get ecommerceObject() {
    if (this.hasEventObjectValue && this.eventObjectValue.length > 0) {
      return { ecommerce: JSON.parse(this.eventObjectValue) }
    } else {
      return null
    }
  }

  get userId() {
    if (this.hasCurrentUserIdValue && this.currentUserIdValue.length > 0) {
      return { userId: this.currentUserIdValue.toString() }
    } else {
      return null
    }
  }
}
