import { Controller } from '@hotwired/stimulus';
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  visit() {
    if (window.getSelection().type !== 'Range') {
      Turbo.visit(this.data.get('show'));
    }
  }
}
