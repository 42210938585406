import { Controller } from "@hotwired/stimulus"
import debounce from "lodash/debounce"
import { post } from "@rails/request.js"

export default class extends Controller {
  static targets = [
    "close",
    "emptyState",
    "loadingState",
    "result",
    "results",
    "finstatName",
    "finstatAddress",
    "finstatCity",
    "finstatZip",
    "finstatCountry",
    "finstatId",
    "finstatTax",
    "finstatVat",
    "finstatVatCheckbox",
  ]

  static values = {
    autocompleteUrl: String,
    detailUrl: String,
  }

  connect() {
    if (!this.hasResultsTarget) console.error("Missing results target.")
    if (!this.autocompleteUrlValue) console.error("Missing autocomplete url value.")
    if (!this.detailUrlValue) console.error("Missing detail url value.")

    this.autocomplete = debounce(this.autocomplete, 200).bind(this)
    this.detail = debounce(this.detail, 200).bind(this)
  }

  // Fetches autocompleted companies json
  async autocomplete(e) {
    const query = e.target.value

    if (e.key === "Escape") return

    if (query.length < 3) {
      this.hideEverything()
    } else {
      this.showLoadingState()

      const response = await post(this.autocompleteUrlValue, {
        contentType: "application/json",
        responseKind: "json",
        body: {
          q: query,
        },
      })

      if (response.ok) {
        const data = await response.json

        console.info(data)

        if (data && data["Results"] && data["Results"].length > 0) {
          this.renderResults(data["Results"])
          this.showResults()
        } else {
          this.showEmptyState()
        }
      }
    }
  }

  // Fetches company detail json
  async detail(event) {
    event.preventDefault()

    if (typeof event.params.ico === "undefined") return

    const response = await post(this.detailUrlValue, {
      contentType: "application/json",
      responseKind: "json",
      body: {
        q: event.params.ico,
      },
    })

    if (response.ok) {
      const data = await response.json

      if (this.hasFinstatNameTarget && data["Name"] !== null) {
        this.finstatNameTargets.forEach((target) => {
          target.value = data["Name"]
        })
      }

      if (this.hasFinstatAddressTarget) {
        let addressArr = [data["Street"], data["StreetNumber"]].filter((item) => {
          return item !== null && item.length > 0
        })

        this.finstatAddressTargets.forEach((target) => {
          target.value = addressArr.join(" ")
        })
      }

      if (this.hasFinstatCityTarget) {
        this.finstatCityTargets.forEach((target) => {
          target.value = data["City"]
        })
      }

      if (this.hasFinstatZipTarget) {
        this.finstatZipTargets.forEach((target) => {
          target.value = data["ZipCode"]
        })
      }

      if (this.hasFinstatIdTarget) {
        this.finstatIdTargets.forEach((target) => {
          target.value = data["Ico"]
        })
      }

      if (this.hasFinstatTaxTarget) {
        this.finstatTaxTargets.forEach((target) => {
          target.value = data["Dic"] || "";
        })
      }

      if (this.hasFinstatCountryTarget) {
        this.finstatCountryTargets.forEach((target) => {
          target.value = data["Country"]
        })
      }

      if (this.hasFinstatVatTarget) {
        const inputEvent = new Event("input")

        this.finstatVatTargets.forEach((target) => {
          target.value = data["IcDPH"]
          target.dispatchEvent(inputEvent)
        })
      }

      if (this.hasFinstatVatCheckboxTarget) {
        const changeEvent = new Event("change")

        if (data["IcDPH"] !== null) {
          this.finstatVatCheckboxTarget.checked = true
        } else {
          this.finstatVatCheckboxTarget.checked = false
        }

        this.finstatVatCheckboxTarget.dispatchEvent(changeEvent)
      }
    }

    this.hideEverything()
  }

  renderResults(results) {
    this.resultsTarget.innerHTML = ""

    let closeElement = this.closeTarget.content.firstElementChild.cloneNode(true)
    this.resultsTarget.appendChild(closeElement)

    results.forEach((result) => {
      let element = this.resultTarget.content.firstElementChild.cloneNode(true)
      let company = element.querySelector("[data-company]")
      let ico = element.querySelector("[data-ico]")

      // Set ICO as element param needed for detail() function
      element.dataset["services-FinstatIcoParam"] = result["Ico"]

      // Fill template with company details shown in dropdown
      company.textContent = `${result["Name"]}, ${result["City"]}`
      ico.textContent = `${result["Ico"]}`

      this.resultsTarget.appendChild(element)
    })
  }

  showResults() {
    this.resultsTarget.removeAttribute("hidden")
    this.emptyStateTarget.setAttribute("hidden", true)
    this.loadingStateTarget.setAttribute("hidden", true)
  }

  showEmptyState() {
    this.resultsTarget.setAttribute("hidden", true)
    this.emptyStateTarget.removeAttribute("hidden")
    this.loadingStateTarget.setAttribute("hidden", true)
  }

  showLoadingState() {
    this.resultsTarget.setAttribute("hidden", true)
    this.emptyStateTarget.setAttribute("hidden", true)
    this.loadingStateTarget.removeAttribute("hidden")
  }

  hideEverything() {
    this.resultsTarget.setAttribute("hidden", true)
    this.emptyStateTarget.setAttribute("hidden", true)
    this.loadingStateTarget.setAttribute("hidden", true)
  }
}
