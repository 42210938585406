import { Controller } from "@hotwired/stimulus"
import { patch } from "@rails/request.js"
import Sortable from "sortablejs"

export default class extends Controller {
  static values = {
    group: String,
  }

  connect() {
    Sortable.create(this.element, {
      group: this.groupValue,
      handle: ".sortable-handle",
      onEnd: async function (event) {
        let requestBody

        const position = event.newIndex
        const path = event.item.dataset.resourceUpdatePath
        const resourceType = event.item.dataset.resourceType
        const resourceId = event.to.dataset.resourceId

        if (resourceType === "section") {
          requestBody = {
            section: {
              row_order_position: position,
            },
          }
        } else if (resourceType === "chapter") {
          requestBody = {
            chapter: {
              row_order_position: position - 1,
              section_id: resourceId,
            },
          }
        } else if (resourceType === "course_recommendation") {
          requestBody = {
            course_recommendation: {
              row_order_position: position,
            },
          }
        } else if (resourceType === "featured_course") {
          requestBody = {
            featured_course: {
              row_order_position: position,
            },
          }
        } else if (resourceType === "category") {
          requestBody = {
            category: {
              row_order_position: position,
            },
          }
        } else if (resourceType === "subcategory") {
          requestBody = {
            category: {
              subcategory_order_position: position,
            },
          }
        } else if (resourceType === "study_path_course") {
          requestBody = {
            study_path_course: {
              row_order_position: position,
              study_path_id: resourceId,
            },
          }
        } else if (resourceType === "question") {
          requestBody = {
            question: {
              row_order_position: position,
              quiz_id: resourceId,
            },
          }
        }

        await patch(path, {
          body: JSON.stringify(requestBody),
          contentType: "application/json; charset=UTF-8",
          responseKind: "turbo-stream",
        })
      },
    })
  }
}
