import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="checkbox-select-all"
export default class extends Controller {
  static values = {
    checkboxCount: Number,
  }

  static targets = ["checkboxAll", "checkbox", "checkedCount"]

  initialize() {
    this.toggle = this.toggle.bind(this)
    this.refresh = this.refresh.bind(this)
  }

  connect() {
    if (!this.hasCheckboxAllTarget) return

    this.checkboxAllTarget.addEventListener("change", this.toggle)
    this.checkboxTargets.forEach((checkbox) => checkbox.addEventListener("change", this.refresh))

    this.refresh()
  }

  disconnect() {
    if (!this.hasCheckboxAllTarget) return

    this.checkboxAllTarget.removeEventListener("change", this.toggle)
    this.checkboxTargets.forEach((checkbox) => checkbox.removeEventListener("change", this.refresh))
  }

  toggle(e) {
    e.preventDefault()

    this.checkboxTargets.forEach((checkbox) => {
      if(checkbox.disabled) return 
      checkbox.checked = e.target.checked
      this.triggerInputEvent(checkbox)
    })

    this.refresh()
  }

  disableAll(e) {
    e.preventDefault()

    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = false;
    })

    this.refresh()
  }

  refresh() {
    const checkboxesCount = this.checkboxTargets.length
    const checkboxesCheckedCount = this.checked.length

    this.checkedCountTargets.forEach((el)=>{
      el.innerText = `${this.checked.length} / ${this.checkboxCountValue}`
    })
    this.checkboxAllTarget.checked = checkboxesCheckedCount > 0
    this.checkboxAllTarget.indeterminate = checkboxesCheckedCount > 0 && checkboxesCheckedCount < checkboxesCount
  }

  triggerInputEvent(checkbox) {
    const event = new Event("input", { bubbles: false, cancelable: true })

    checkbox.dispatchEvent(event)
  }

  reload(e) {
    e.preventDefault()

    const modal = document.getElementById("modal")

    if (modal) {
      modal.reload()
    }
  }

  get checked() {
    return this.checkboxTargets.filter((checkbox) => {
      return checkbox.checked && !checkbox.disabled
    })
  }

  get unchecked() {
    return this.checkboxTargets.filter((checkbox) => {
      return !checkbox.checked
    })
  }
}
