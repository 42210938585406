import { Controller } from "@hotwired/stimulus"
import  _throttle from "lodash/throttle"
import { gsap } from "gsap";

// Connects to data-controller="parallax"
export default class extends Controller {
  static targets = [
    'layer',
  ]
  // Throttled animation function - performance opt.
  throttled = _throttle(this.animateLayers, 17, {trailing: false}).bind(this);

  connect() {
    //If user uses mouse pointer    
    if (matchMedia('(pointer:fine)').matches) {
      // Event listeners
      this.element.addEventListener('mousemove', this.throttled);
      //this.element.addEventListener('mouseleave', this.resetLayers.bind(this));
    }
  }

  disconnect() {
    // Cleanup
    // Remove listeners
    this.element.removeEventListener('mousemove', this.throttled);
    //this.element.removeEventListener('mouseleave', this.resetLayers);
  }

  animateLayers(e) {
    const maxOffsetX = 10;
		const maxOffsetY = 10;

    let divPosX = Math.floor(e.clientX - this.element.getBoundingClientRect().left);
    let divPosY = Math.floor(e.clientY - this.element.getBoundingClientRect().top);

    let h_percentage = ((2/this.element.offsetWidth)*divPosX)-1;
    let v_percentage = ((2/this.element.offsetHeight)*divPosY)-1;


    this.layerTargets.forEach((layer)=>{
      const depth = layer.getAttribute("data-depth") ? layer.getAttribute("data-depth") : 1 ;
      gsap.to(layer, {duration: .75, translateY: `${v_percentage*maxOffsetY*depth}px`, translateX: `${h_percentage*maxOffsetX*depth}px`, ease: "slow"})
    })
  }

  resetLayers() {
    this.layerTargets.forEach((layer)=>{
      gsap.to(layer, {duration: 1.5, translateY: '0px', translateX: '0px', ease: "power1.inOut"})
    })
  }
}
