import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"
import camelCase from "lodash/camelCase"
import debounce from "lodash/debounce"

export default class extends Controller {
  static values = {
    validationUrl: String,
    validatableAttributes: Array,
    originalSubmitButtonLabel: String,
    alternativeSubmitButtonLabel: String,
  }

  static targets = [
    "companyId",
    "billingFields",
    "paymentMethods",
    "form",
    "invoiceFields",
    "invoiceCheckbox",
    "price",
    "submit",
    "submitButton",
    // "vatId",
    // "vatIdCheckbox",
    // "vatIdInputGroup",
    "voucherCheckbox",
    "voucherCode",
    "voucherCodeInputGroup",
    "recurrenceCycle",
    "recurrencePeriod",
  ]

  initialize() {
    this.validate = debounce(this.validateForm, 200).bind(this)
  }

  connect() {
    this.validateForm()
  }

  async validateForm() {
    const response = await post(this.validationUrl, {
      body: {
        company_id: this.companyId,
        has_invoice: this.hasInvoice,
        has_voucher: this.hasVoucherCode,
        voucher_code: this.voucherCode,
        has_vat_id: this.hasVatId,
        // vat_id: this.vatId,
        item_type: this.purchaseableType,
        item_id: this.purchaseableId,
        recurrence_cycle: this.recurrenceCycle,
        recurrence_period: this.recurrencePeriod,
      },
      responseKind: "json",
    })

    if (response.ok) {
      const json = await response.json

      this.handlePriceChange(json.data.attributes)
      this.handleValidationErrors(json.data.errors)
    } else {
      console.error("Validation failed.")
    }
  }

  handlePriceChange(attributes) {
    this.submitTarget.innerHTML = attributes.submit_button_label
    this.priceTarget.innerHTML = attributes.price_label

    if (attributes.amount === 0) {
      this.invoiceFieldsTarget.setAttribute("hidden", true)
      this.paymentMethodsTarget.setAttribute("hidden", true)
    } else {
      this.invoiceFieldsTarget.removeAttribute("hidden")
      this.paymentMethodsTarget.removeAttribute("hidden")
    }
  }

  handleValidationErrors(errors) {
    errors ||= []

    // ['vat_id', 'voucher_code', ...].forEach()
    this.validatableAttributesValue.forEach((attribute) => {
      // Find corresponding error for attribute if any
      const error = errors.find((e) => {
        return e.attribute === attribute
      })

      if (error) {
        // cleanup & hide validation block for error.attribute
        const inputGroupTarget = this.inputGroupTargetFor(error)

        this[inputGroupTarget].querySelector("[data-validation-errors]").innerHTML = error.title
      } else {
        // render & show validation block for attribute
        const inputGroupTarget = this.inputGroupTargetFor(attribute)

        this[inputGroupTarget].querySelector("[data-validation-errors]").innerHTML = ""
      }
    })
  }

  inputGroupTargetFor(identifier) {
    // attribute_name -> attributeNameInputGroupTarget
    let attribute = ""

    if (typeof identifier === "object") {
      attribute = camelCase(identifier.attribute)
    } else if (typeof identifier === "string") {
      attribute = camelCase(identifier)
    } else {
      console.error("Unknown identifier.")
    }

    return `${attribute}InputGroupTarget`
  }

  disableSubmitButton() {
    if (!this.hasSubmitButtonTarget) {
      console.error("No submit button target found")
    } else {
      this.submitButtonTarget.disabled = true
    }
  }

  get companyId() {
    return this.companyIdTarget.value.trim()
  }

  get purchaseableType() {
    return this.formTarget.elements["payment_form[purchaseable_type]"].value
  }

  get purchaseableId() {
    return this.formTarget.elements["payment_form[purchaseable_id]"].value
  }

  get hasInvoice() {
    return this.invoiceCheckboxTarget.checked ? "1" : "0"
  }

  // get hasVatId() {
  //   return this.vatIdCheckboxTarget.checked ? "1" : "0"
  // }

  // get vatId() {
  //   return this.vatIdTarget.value.trim()
  // }

  get hasVoucherCode() {
    return this.voucherCheckboxTarget.checked ? "1" : "0"
  }

  get voucherCode() {
    return this.voucherCodeTarget.value.trim()
  }

  get validationUrl() {
    return this.validationUrlValue
  }

  get recurrenceCycle() {
    return this.recurrenceCycleTarget.value
  }

  get recurrencePeriod() {
    return this.recurrencePeriodTarget.value
  }
}
