import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static values = {
    url: String,
  }

  connect() {
    const query = document.URL.split("#")[1]

    if (typeof query === "undefined") return

    //TODO: Refactor this to function
    fetch(`${this.urlValue}?${query}`, {
      headers: { Accept: "text/vnd.turbo-stream.html" },
    })
      .then((r) => r.text())
      .then((r) =>
        r.replace('turbo-stream action="append" target="courses"', 'turbo-stream action="update" target="courses"')
      )
      .then((html) => Turbo.renderStreamMessage(html))
  }

  filter(e) {
    e.preventDefault()

    const filterParamsObj = JSON.parse(e.target.dataset.filterParams)

    const filterParams = this.serializeFilterParamsObj(filterParamsObj).join("&")
    const query = encodeURI(filterParams)

    fetch(`${this.urlValue}?${query}`, {
      headers: { Accept: "text/vnd.turbo-stream.html" },
    })
      .then((r) => r.text())
      .then((r) =>
        r.replace('turbo-stream action="append" target="courses"', 'turbo-stream action="update" target="courses"')
      )
      .then((html) => Turbo.renderStreamMessage(html))
      .then(() => history.replaceState(history.state, "", `${this.urlValue}#${query}`))
  }

  resetFilter(e) {
    e.preventDefault()

    fetch(`${this.urlValue}`, {
      headers: { Accept: "text/vnd.turbo-stream.html" },
    })
      .then((r) => r.text())
      .then((r) =>
        r.replace('turbo-stream action="append" target="courses"', 'turbo-stream action="update" target="courses"')
      )
      .then((html) => Turbo.renderStreamMessage(html))
      .then(() => history.replaceState(history.state, "", `${this.urlValue}`))
  }

  serializeFilterParamsObj(filterParams) {
    let str = []

    for (let param in filterParams) {
      if (Array.isArray(filterParams[param])) {
        for (let item in filterParams[param]) {
          str.push(`${param}[]=${filterParams[param][item]}`)
        }
      } else if (typeof filterParams[param] == "string") {
        str.push(`${param}=${filterParams[param]}`)
      } else {
        console.error(`Unknown param: ${param} -> ${filterParams[param]}`)
      }
    }

    return str
  }
}
