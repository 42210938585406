import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="teacher-channel--course-attachment"
export default class extends Controller {
  static values = {
    previewUrl: String,
  }

  connect() {}

  updatePreview(e) {
    const id = e.target.value

    get(`${this.previewUrlValue}?course_id=${id}`, {
      responseKind: "turbo-stream",
    })
  }

  findSelectedCourse(id) {
    return this.availableCoursesValue.find((course) => course.id === id)
  }
}
