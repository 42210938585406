import { Controller } from "@hotwired/stimulus"
import { patch } from "@rails/request.js"

export default class extends Controller {
  static classes = ["autoplayButtonOn", "autoplayButtonOff", "autoplayTogglerOn", "autoplayTogglerOff"]
  static targets = ["autoplayButton", "autoplayToggler", "autoplayOnLabel", "autoplayOffLabel", "video"]

  static values = {
    autoplay: Boolean,
    toggleAutoplayUrl: String,
  }

  async toggleAutoplay() {
    const response = await patch(this.toggleAutoplayUrlValue, {
      responseKind: "json",
    })

    if (response.ok) {
      const json = await response.json

      this.autoplayValue = json.autoplay
    } else {
      console.error("Error trying to toggle autoplay.")
    }
  }

  autoplayValueChanged() {
    if(this.hasAutoplayButtonTarget){
      if(this.autoplayValue){
        this.autoplayButtonTarget.classList.add(...this.autoplayButtonOnClasses)
        this.autoplayButtonTarget.classList.remove(...this.autoplayButtonOffClasses)
      }
      else{
        this.autoplayButtonTarget.classList.add(...this.autoplayButtonOffClasses)
        this.autoplayButtonTarget.classList.remove(...this.autoplayButtonOnClasses)
      }
    }

    if(this.hasAutoplayTogglerTarget){
      if(this.autoplayValue){
        this.autoplayTogglerTarget.classList.add(...this.autoplayTogglerOnClasses)
        this.autoplayTogglerTarget.classList.remove(...this.autoplayTogglerOffClasses)
      }
      else{
        this.autoplayTogglerTarget.classList.add(...this.autoplayTogglerOffClasses)
        this.autoplayTogglerTarget.classList.remove(...this.autoplayTogglerOnClasses)
      }
    }

    if(this.hasAutoplayOnLabelTarget){
      this.autoplayOnLabelTarget.classList.toggle("hidden", !this.autoplayValue)
    }

    if(this.hasAutoplayOffLabelTarget){
      this.autoplayOffLabelTarget.classList.toggle("hidden", this.autoplayValue)
    }
  }
}
