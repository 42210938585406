import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"
import uniq from "lodash/uniq"

// Connects to data-controller="b2b--enrollments-wizard"
export default class extends Controller {
  static targets = [
    "courseCheckbox",
    "coursesCount",
    "courseIdsInput",
    "coursesBottomNavigation",
    "employeeCheckbox",
    "employeeIdsInput",
    "employeesCount",
    "employeesBottomNavigation",
    "deadlineSelect",
  ]

  static values = {
    submitUrl: String,
  }

  initialize() {
    this.initializeLocalStorage()
  }

  connect() {
    this.resetStorage()

    document.addEventListener("turbo:load", () => {
      this.initializeSelectedCourses()
      this.updateSelectedCoursesCounter()
      this.initializeSelectedEmployees()
      this.updateSelectedEmployeesCounter()
    })
  }

  handleCourseClick(e) {
    const isChecked = e.target.checked
    const courseId = e.target.value

    const data = this.skmData

    if (isChecked) {
      data.skmEnrollmentsWizard.selectedCourses.push(courseId)
    } else {
      data.skmEnrollmentsWizard.selectedCourses.pop(courseId)
    }

    this.updateSkmData(data)
  }

  handleEmployeeClick(e) {
    const isChecked = e.target.checked
    const employeeId = e.target.value

    const data = this.skmData

    if (isChecked) {
      data.skmEnrollmentsWizard.selectedEmployees.push(employeeId)
    } else {
      data.skmEnrollmentsWizard.selectedEmployees.pop(employeeId)
    }

    this.updateSkmData(data)
  }

  async submit(e) {
    e.preventDefault()

    const response = await post(this.submitUrlValue, {
      body: {
        course_ids: this.skmData.skmEnrollmentsWizard.selectedCourses,
        employee_ids: this.skmData.skmEnrollmentsWizard.selectedEmployees,
        deadline_in_weeks: this.selectedDeadline,
      },
      responseKind: "turbo-stream",
    })

    if (response.ok) {
      this.resetStorage()
    }
  }

  initializeSelectedCourses() {
    const selectedCourses = this.selectedCourses

    this.courseCheckboxTargets.forEach((courseCheckbox) => {
      const courseId = courseCheckbox.value

      courseCheckbox.checked = this.isCourseSelected(courseId, selectedCourses)
    })
  }

  updateSelectedCoursesCounter() {
    if (this.hasCoursesCountTarget) {
      this.coursesCountTarget.innerHTML = this.selectedCourses.length
    }

    if (this.hasCoursesBottomNavigationTarget) {
      this.coursesBottomNavigationTarget.hidden = this.selectedCourses.length == 0
    }
  }

  async clearSelectedCourses() {
    const data = JSON.parse(localStorage.getItem("skmData"))

    data.skmEnrollmentsWizard.selectedCourses = []

    await this.updateSkmData(data)
    this.initializeSelectedCourses()
  }

  updateSelectedEmployeesCounter() {
    if (this.hasEmployeesCountTarget) {
      this.employeesCountTarget.innerHTML = this.selectedEmployees.length
    }

    if (this.hasEmployeesBottomNavigationTarget) {
      this.employeesBottomNavigationTarget.hidden = this.selectedEmployees.length == 0
    }
  }

  initializeSelectedEmployees() {
    const selectedEmployees = this.selectedCourses

    this.employeeCheckboxTargets.forEach((employeeCheckbox) => {
      const employeeId = employeeCheckbox.value

      employeeCheckbox.checked = this.isEmployeeSelected(employeeId, selectedEmployees)
    })
  }

  async clearSelectedEmployees() {
    const data = this.skmData

    data.skmEnrollmentsWizard.selectedEmployees = []

    await this.updateSkmData(data)
    this.initializeSelectedEmployees()
  }

  initializeLocalStorage() {
    const defaultSkmData = {
      skmEnrollmentsWizard: {
        selectedCourses: [],
        selectedEmployees: [],
      },
    }

    if (localStorage.getItem("skmData") == null) {
      localStorage.setItem("skmData", JSON.stringify(defaultSkmData))
    }
  }

  resetStorage() {
    localStorage.removeItem("skmData")

    this.initializeLocalStorage()
    this.initializeSelectedCourses()
    this.initializeSelectedEmployees()
  }

  updateSkmData(newData) {
    newData.skmEnrollmentsWizard.selectedCourses = uniq(newData.skmEnrollmentsWizard.selectedCourses)
    newData.skmEnrollmentsWizard.selectedEmployees = uniq(newData.skmEnrollmentsWizard.selectedEmployees)

    localStorage.setItem("skmData", JSON.stringify(newData))

    this.updateSelectedCoursesCounter()
    this.updateSelectedEmployeesCounter()
  }

  isCourseSelected(courseId, selectedCourses) {
    return selectedCourses.indexOf(courseId) !== -1
  }

  isEmployeeSelected(employeeId, selectedEmployees) {
    return selectedEmployees.indexOf(employeeId) !== -1
  }

  get skmData() {
    return JSON.parse(localStorage.getItem("skmData"))
  }

  get selectedCourses() {
    return this.skmData.skmEnrollmentsWizard.selectedCourses
  }

  get selectedEmployees() {
    return this.skmData.skmEnrollmentsWizard.selectedEmployees
  }

  get selectedDeadline() {
    if (this.hasSelectedDeadlineTarget) {
      return this.deadlineSelectTarget.value;
    } else {
      return 0;
    }
  }
}
