import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["logoPreview"]

  updateLogoPreview(evt) {
    if (this.hasLogoPreviewTarget) {
      const reader = new FileReader()

      reader.onload = (e) => {
        this.logoPreviewTarget.setAttribute("src", e.target.result)
      }

      reader.readAsDataURL(evt.target.files[0])
    } else {
      throw new Error("Element logoPreview could not be found in the DOM.")
    }
  }
}
