import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="above-page-banner"
export default class extends Controller {
  // Local variables

  // External variables
  static values = {
    open: Boolean,
    slug: String
  }

  initialize() {
    // Check for existing settings
    this.readLsSettings();
  }

  // Read settings from local storage
  readLsSettings() {
    // Read local storage settings
    const bannerLS = localStorage.getItem(this.slugValue);

    // If the local storage variable doesnt exist, create it (true)
    if(!bannerLS){
      this.openValue = true;
    } else {
      this.openValue = bannerLS;
    }
  }

  // Write settings to local storage
  storeLsSettings() {
    localStorage.setItem(this.slugValue, this.openValue)
  }

  // Close banner
  close() {
    this.openValue = false;
  }

  // Watcher for change in open state
  openValueChanged(){
    this.storeLsSettings()
    this.element.classList.toggle('hidden', !this.openValue)
  }
}
