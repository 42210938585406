import { Application } from "@hotwired/stimulus"
import Autocomplete from "stimulus-autocomplete"
import Dialog from '@stimulus-components/dialog'

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus = application

application.register('autocomplete', Autocomplete)
application.register('dialog', Dialog)

export { application }
