import { Controller } from "@hotwired/stimulus"
import Uppy from "@uppy/core"
import Tus from "@uppy/tus"
import Slovak from "@uppy/locales/lib/sk_SK"
import Czech from "@uppy/locales/lib/cs_CZ"
import Vimeo from "../plugins/uppy-vimeo-plugin"

// Connects to data-controller="vimeo-upload"
export default class extends Controller {
  static targets = [
    "fileInput",
    "fileInputLabel",
    "pendingState",
    "pendingStateMessage",
    "resetButton",
    "videoIframe",
    "videoUrlInput",
  ]

  static values = {
    videoUrl: String,
    checkVideoUrl: String,
    prepareVideoUrl: String,
    processVideoUrl: String,
    preparingProgressMessage: String,
    processingProgressMessage: String,
    uploadingProgressMessage: String,
    locale: String,
  }

  addFile(evt) {
    const file = evt.target.files[0]

    this.uppy.addFile({
      name: file.name,
      type: file.type,
      data: file,
    })
  }

  connect() {
    this.csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute("content")

    this.uppy = new Uppy({
      debug: true,
      autoProceed: true,
      locale: this.localeValue == "sk" ? Slovak : Czech,
      restrictions: {
        minNumberOfFiles: 1,
        maxNumberOfFiles: 1,
        allowedFileTypes: ["video/*"],
      },
    })
      .use(Tus, {})
      .use(Vimeo, {
        prepareVideoUrl: this.prepareVideoUrlValue,
        processVideoUrl: this.processVideoUrlValue,
      })

    this.setupUppyEvents()

    if (this.hasVideoUrlValue && this.videoUrlValue !== "") {
      this.showVimeoPlayer(this.videoUrlValue.split("/").pop())

      this.hideFileInputLabel()
    }
  }

  async pollVideoProcessingStatus(vimeoId) {
    const executePoll = async (resolve, reject) => {
      const response = await fetch(this.checkVideoUrlValue, {
        method: "post",
        headers: {
          "X-CSRF-TOKEN": this.csrfToken,
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
        body: JSON.stringify({
          video: {
            vimeo_id: vimeoId,
          },
        }),
      })

      const result = await response.json()

      if (result && result.transcode_status === "complete") {
        this.hidePendingState()
        this.showVimeoPlayer(vimeoId)
      } else if (result && result.transcode_status === "in_progress") {
        this.showPendingState(this.processingProgressMessageValue)
        setTimeout(executePoll, 3000, resolve, reject)
      } else {
        reject("Video processing failed.")
      }
    }

    return new Promise(executePoll)
  }

  resetVideoUrl() {
    this.videoUrlInputTarget.value = ""
    this.hideVimeoPlayer()
    this.showFileInputLabel()
  }

  setupUppyEvents() {
    this.uppy.on("file-added", () => {
      this.hideFileInputLabel()
      this.showPendingState(this.preparingProgressMessageValue)
    })

    this.uppy.on("progress", (progress) => {
      const progressMessage = `${this.uploadingProgressMessageValue} (${progress}%)`

      this.showPendingState(progressMessage)
    })

    this.uppy.on("complete", (result) => {
      // Extract vimeoId from vimeo.uri
      const vimeoId = result.successful[0].vimeo.uri.split("/").pop()

      // Update video_url input value
      this.videoUrlInputTarget.value = `https://vimeo.com/${vimeoId}`

      // Reset file input value to prevent reupload
      this.fileInputTarget.value = ""

      // Start polling video processing status
      this.pollVideoProcessingStatus(vimeoId).catch((error) => {
        console.error(error)
      })
    })
  }

  showPendingState(message) {
    this.pendingStateTarget.classList.remove("hidden")
    this.pendingStateTarget.classList.add("flex")

    this.pendingStateMessageTarget.innerHTML = message
  }

  hidePendingState() {
    this.pendingStateTarget.classList.remove("flex")
    this.pendingStateTarget.classList.add("hidden")

    this.pendingStateMessageTarget.innerHTML = ""
  }

  showFileInputLabel() {
    this.fileInputLabelTarget.classList.remove("hidden")
    this.fileInputLabelTarget.classList.add("flex")
  }

  hideFileInputLabel() {
    this.fileInputLabelTarget.classList.remove("flex")
    this.fileInputLabelTarget.classList.add("hidden")
  }

  showResetButton() {
    this.resetButtonTarget.classList.remove("hidden")
  }

  hideResetButton() {
    this.resetButtonTarget.classList.add("hidden")
  }

  showVimeoPlayer(vimeoId) {
    this.videoIframeTarget.src = `https://player.vimeo.com/video/${vimeoId}`
    this.videoIframeTarget.classList.remove("hidden")

    this.showResetButton()
  }

  hideVimeoPlayer() {
    this.videoIframeTarget.src = ""
    this.videoIframeTarget.classList.add("hidden")

    this.hideResetButton()
  }
}
