import { Controller } from "@hotwired/stimulus"
import Headroom from "headroom.js"

export default class extends Controller {
  connect() {
    const headroom = new Headroom(this.element, {
      offset: 800,
      tolerance: 0
    })

    headroom.init()
  }
}
