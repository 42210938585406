import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"
import Player from "@vimeo/player"

export default class extends Controller {
  static targets = ["video"]

  static values = {
    autoplay: Boolean,
    nextChapterUrl: String,
    videoId: String,
  }

  connect() {
    this.player = new Player(this.videoTarget, {
      id: this.videoIdValue,
      responsive: true,
    })

    this.playbackRate = localStorage.getItem("playbackRate")
    this.playbackQuality = localStorage.getItem("playbackQuality")

    this.initializeVideoEndedHandler()
    this.initializePlaybackRateChangeHandler()

    if (this.autoplayValue) {
      this.play()
    }

    if (this.playbackRate) {
      this.player.setPlaybackRate(this.playbackRate)
    }

    if (this.playbackQuality) {
      this.player.setQuality(this.playbackQuality)
    }
  }

  play() {
    this.player.play().catch((error) => {
      switch (error.name) {
        case "PasswordError":
          console.warn("Cannot play password protected video.")
          break
        case "PrivacyError":
          console.warn("Cannot play this video due to privacy error.")
          break
        case "NotAllowedError":
          this.handleNotAllowedError()
          break
        default:
          console.warn("Error playing Vimeo video.")
          break
      }
    })
  }

  handleNotAllowedError() {
    console.warn("Autoplay prevented by browser policy, Waiting for user interaction.")

    // Define custom function to be used when adding/removing event listener
    const play = function () {
      this.play()

      // Unbind the listener. In case of user pausing the video, it would start playing upon
      // clicking anywhere on page.
      document.body.removeEventListener("click", play)
    }.bind(this)

    // Bind the listener.
    document.body.addEventListener("click", play)
  }

  initializeVideoEndedHandler() {
    this.player.on("ended", async () => {
      if (this.nextChapterUrlValue.length === 0) return
      if (this.autoplayValue === false) return

      await get(this.nextChapterUrlValue, {
        responseKind: "turbo-stream",
      })
    })
  }

  initializePlaybackRateChangeHandler() {
    this.player.on("playbackratechange", (data) => {
      localStorage.setItem("playbackRate", data.playbackRate)
    })

    this.player.on("qualitychange", (data) => {
      localStorage.setItem("playbackQuality", data.quality)
    })
  }
}
