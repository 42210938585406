import { Controller } from "@hotwired/stimulus"
import debounce from "lodash/debounce"
import { post } from "@rails/request.js"

export default class extends Controller {
  static targets = ["clearButton", "query", "mobileOverlay", "searchIcon", "loadingIcon", "desktopDropdown"]
  static classes = ["open", "close"]
  static values = {
    url: String,
  }

  initialize() {
    if (this.hasMobileOverlayTarget) {
      this.mobileSearchOpen = false
    }

    if (this.hasDesktopDropdownTarget) {
      this.dropdownOpen = false
    }

    this.perform = debounce(this.submitForm, 500).bind(this)
  }

  connect() {
    this.ensureDependencies()
    this.setupEvents()
  }

  submitForm() {
    if (this.queryTarget.value) {
      this.showClearButton()
    } else {
      this.hideClearButton()
    }

    this.showLoadingState()

    post(this.urlValue, {
      body: {
        query: this.queryTarget.value,
      },
      responseKind: "turbo-stream",
    }).then(() => {
      this.hideLoadingState()
    })
  }

  clear() {
    this.queryTarget.value = ""
    this.submitForm()
  }

  searchRecent({ params }) {
    if (!params.query) return

    this.queryTarget.value = params.query
    this.submitForm()
  }

  toggleMobileOverlay() {
    if (!this.hasMobileOverlayTarget) return

    if (this.mobileSearchOpen === true) {
      this.mobileOverlayTarget.classList.add(...this.closeClasses)
      this.mobileOverlayTarget.classList.remove(...this.openClasses)
      this.queryTarget.blur()
      this.queryTarget.value = ""

      this.submitForm()

      this.mobileSearchOpen = false
    } else {
      this.mobileOverlayTarget.classList.add(...this.openClasses)
      this.mobileOverlayTarget.classList.remove(...this.closeClasses)
      this.queryTarget.focus()
      this.queryTarget.value = ""

      this.submitForm()

      this.mobileSearchOpen = true
    }
  }

  openDesktopDropdown() {
    if (!this.hasDesktopDropdownTarget) return

    this.dropdownOpen = true
    this.desktopDropdownTarget.classList.remove("hidden")
    this.queryTarget.focus()

    this.submitForm()
  }

  closeDesktopDropdown() {
    if (!this.hasDesktopDropdownTarget) return

    this.dropdownOpen = false
    this.desktopDropdownTarget.classList.add("hidden")
    this.queryTarget.blur()
    this.queryTarget.value = ""

    this.submitForm()
  }

  showLoadingState() {
    this.searchIconTarget.classList.add("hidden")
    this.loadingIconTarget.classList.remove("hidden")
  }

  hideLoadingState() {
    this.loadingIconTarget.classList.add("hidden")
    this.searchIconTarget.classList.remove("hidden")
  }

  showClearButton() {
    this.clearButtonTarget.classList.add("flex")
    this.clearButtonTarget.classList.remove("hidden")
  }

  hideClearButton() {
    this.clearButtonTarget.classList.add("hidden")
    this.clearButtonTarget.classList.remove("flex")
  }

  ensureDependencies() {
    if (!this.hasQueryTarget) {
      console.error("GlobalSearchController: Missing query target.")
    }

    if (!this.hasUrlValue) {
      console.error("GlobalSearchController: Missing url value.")
    }
  }

  setupEvents() {
    this.queryTarget.addEventListener("focusin", () => {
      if (this.hasDesktopDropdownTarget) {
        this.openDesktopDropdown()
      }
    })

    if (this.hasDesktopDropdownTarget) {
      // Either improve this or remove it. It causes bugs when inside textareas.
      // document.addEventListener("keyup", (evt) => {
      //   if (evt.code !== "Slash") return
      //   // Ignore when modifier key is pressed
      //   if (evt.altKey || evt.ctrlKey || evt.metaKey || evt.shiftKey) return
      //   // Ignore global key when in a form
      //   if (/^(?:input|textarea|select|button)$/i.test(evt.target.tagName)) return

      //   evt.preventDefault()

      //   this.openDesktopDropdown()
      // })

      document.addEventListener("keyup", (evt) => {
        if (evt.code !== "Escape") return
        if (this.dropdownOpen === false) return

        evt.preventDefault()

        this.closeDesktopDropdown()
      })

      document.addEventListener("click", (evt) => {
        if (this.dropdownOpen && !this.element.contains(evt.target)) {
          this.closeDesktopDropdown()
        }
      })
    }
  }
}
