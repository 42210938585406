import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static values = {
    updateBrowserHistory: Boolean,
    href: String,
  }

  forceTurboStream(e) {
    e.preventDefault()

    const href = this.hrefValue

    fetch(href, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    })
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html))
      .then(() => {
        if (this.updateBrowserHistoryValue) {
          history.replaceState(history.state, "", href)
        }
      })
  }
}
