"use strict"

import BasePlugin from "@uppy/core/lib/BasePlugin"

export default class Vimeo extends BasePlugin {
  constructor(uppy, opts) {
    super(uppy, opts)

    this.id = "Vimeo"
    this.type = "uploader"

    this.preUpload = this.preUpload.bind(this)
    this.postUpload = this.postUpload.bind(this)

    this.csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content")

    uppy.on("upload-success", async (file) => {
      const fileVimeoId = file.vimeo.uri.split("/").pop()

      const response = await fetch(opts.processVideoUrl, {
        method: "post",
        headers: {
          "X-CSRF-TOKEN": this.csrfToken,
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
        body: JSON.stringify({
          video: {
            title: file.name,
            vimeo_id: fileVimeoId,
          },
        }),
      })

      const result = await response.json()

      if (result && result.message === "Success") {
        console.info("File processed successfully.")
      } else {
        console.error("File was not processed.")
      }
    })
  }

  preUpload(fileIds) {
    const promises = fileIds.map(async (fileId) => {
      const file = this.uppy.getFile(fileId)

      this.uppy.emit("preprocess-progress", file, {
        mode: "indeterminate",
        message: "Moment..",
      })

      const response = await fetch(this.opts.prepareVideoUrl, {
        method: "post",
        headers: {
          "X-CSRF-TOKEN": this.csrfToken,
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
        body: JSON.stringify({
          file_name: file.name,
          file_size: file.size,
        }),
      })

      const { upload, uri } = await response.json()

      this.uppy.setFileState(fileId, {
        tus: Object.assign({}, file.tus, {
          uploadUrl: upload.upload_link,
          chunkSize: 5 * 1024 * 1024,
        }),
        vimeo: {
          uri: uri,
        },
        remote: Object.assign({}, file.remote, {
          body: Object.assign({}, file.remote.body, {
            uploadUrl: upload.upload_link,
          }),
        }),
      })
    })

    const emitPreprocessCompleteForAllFiles = () => {
      fileIds.forEach((fileId) => {
        const file = this.uppy.getFile(fileId)

        this.uppy.emit("preprocess-complete", file)
      })
    }

    return Promise.all(promises).then(emitPreprocessCompleteForAllFiles)
  }

  postUpload() {
    console.info("Post upload complete!")
  }

  install() {
    this.uppy.addPreProcessor(this.preUpload)
    this.uppy.addPostProcessor(this.postUpload)
  }

  uninstall() {
    this.uppy.removePreProcessor(this.preUpload)
    this.uppy.removePostProcessor(this.postUpload)
  }
}
