import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="checkout--purchase-as-gift"
export default class extends Controller {
  static targets = [
    "addressDeliveryFields",
    "pickupPointDeliveryFields",
    "contactDetailsFields",
    "packetaPlace",
    "packetaStreet",
    "packetaZip",
    "packetaCity",
    "packetaEmptyState",
    "packetaSelectedState",
    "packetaPickupPointIdField",
    "packetaPlaceField",
    "packetaAddressField",
    "packetaCityField",
    "packetaZipField",
    "validation",
  ]

  static values = {
    pickupPointSelected: Boolean,
    locale: String,
  }

  connect() {
    this.packetaApiKey = "XXX XXX API550bc18b24dc5b37 XXX"
    this.packetaOptions = {
      country: "cz,sk",
      language: this.localeValue,
    }

    this.showSelectedPickupPoint = (point) => {
      if (point) {
        this.packetaPlaceTarget.innerText = point.place
        this.packetaStreetTarget.innerText = point.street
        this.packetaZipTarget.innerText = point.zip
        this.packetaCityTarget.innerText = point.city

        this.packetaPickupPointIdFieldTarget.value = point.id
        this.packetaPlaceFieldTarget.value = point.place
        this.packetaAddressFieldTarget.value = point.street
        this.packetaCityFieldTarget.value = point.city
        this.packetaZipFieldTarget.value = point.zip

        this.setSelectedState()
      } else {
        this.setEmptyState()
      }
    }
  }

  selectAddressDelivery() {
    this.contactDetailsFieldsTarget.classList.remove("hidden")
    this.addressDeliveryFieldsTarget.classList.remove("hidden")
    this.pickupPointDeliveryFieldsTarget.classList.add("hidden")

    this.clearValidations()
  }

  selectPickupPointDelivery() {
    this.contactDetailsFieldsTarget.classList.remove("hidden")
    this.addressDeliveryFieldsTarget.classList.add("hidden")
    this.pickupPointDeliveryFieldsTarget.classList.remove("hidden")

    this.clearValidations()
    if (!this.pickupPointSelectedValue) {
      this.openWidgetModal()
    }
  }

  openWidgetModal() {
    window.Packeta.Widget.pick(this.packetaApiKey, this.showSelectedPickupPoint, this.packetaOptions)
  }

  setEmptyState() {
    this.packetaEmptyStateTarget.classList.remove("hidden")
    this.packetaSelectedStateTarget.classList.add("hidden")

    this.pickupPointSelectedValue = false
  }

  setSelectedState() {
    this.packetaEmptyStateTarget.classList.add("hidden")
    this.packetaSelectedStateTarget.classList.remove("hidden")

    this.pickupPointSelectedValue = true
  }

  clearValidations() {
    this.validationTargets.forEach((element) => {
      element.innerText = ""
    })
  }
}
