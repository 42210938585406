import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"

// Connects to data-controller="notifications"
export default class extends Controller {
  static values = {
    url: String,
    read: Boolean
  }

  markAsRead() {
    // Return if notification is already read
    if(this.readValue == true) return;

    post(this.urlValue, {
      responseKind: 'turbo-stream'
    })
  }
}
