import { Controller } from "@hotwired/stimulus"
import _debounce from "lodash/debounce"

// Connects to data-controller="search-in"
export default class extends Controller {

  static values = {
    componentIsWorking: Boolean
  }

  static targets = [
    "searchField",
    "searchItem",
    "clearSearch",
    "iconSearch",
    "iconSpinner"
  ]

  connect() {
    // Debounced event func. expression - so that it can be used inside anonymous function and still be debounced
    const debounceInputEvent = _debounce(this.searchIn , 300).bind(this);
    // Event listener
    this.searchFieldTarget.addEventListener('input', (evt) => {
      // Switch on loading status
      this.componentIsWorkingValue = true;
      this.clearSearchTarget.classList.toggle('hidden', !this.searchFieldTarget.value != '')
      return debounceInputEvent();
    });

    // Initial checks
    this.componentIsWorkingValueChanged();
    this.clearSearchTarget.classList.toggle('hidden', !this.searchFieldTarget.value != '')
  }
 
  searchIn(){
    this.searchItemTargets.forEach(el => {
      const searchTerm = this.searchFieldTarget.value;
      const normalizedSearchTerm = searchTerm.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      
      // If the target input is found in search toggle its 'hidden' class and its inputs disabled status
      el.classList.toggle( 'hidden' , !el.dataset.searchId.toLowerCase().includes( normalizedSearchTerm ) );
      el.querySelector('input').disabled = !el.dataset.searchId.toLowerCase().includes( normalizedSearchTerm );

      // Trigger custom event, so that other controllers might react to it
      dispatchEvent(new Event('searchInSearchFinished'))
    });

    // Turn off loading status
    this.componentIsWorkingValue = false;
  }

  clearSearch(){
    this.searchFieldTarget.value = '';
    this.searchIn();
  }

  componentIsWorkingValueChanged(){
    this.iconSearchTarget.classList.toggle('hidden', this.componentIsWorkingValue)
    this.iconSpinnerTarget.classList.toggle('hidden', !this.componentIsWorkingValue)
  }
}
