import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="counter"
export default class extends Controller {
  static targets = [
    "input"
  ]

  static values = {
    count: Number
  }

  increment(e){
    this.countValue = parseInt(this.countValue+1);
  }

  decrement(e){
    if(this.countValue > 0){
      this.countValue = parseInt(this.countValue-1);
    }
  }

  setCountTo(event){
    this.countValue = parseInt(event.detail.count)
  }

  countValueChanged(){
    // Render value into Input
    this.inputTarget.value = this.countValue;

    // Trigger custom event, so that other controllers might react to it
    dispatchEvent(new CustomEvent('counterCountValueChanged', { detail: {count: this.countValue} }))
  }
  
}
