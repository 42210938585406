import flatpickr from "flatpickr"
import { Controller } from "@hotwired/stimulus"
import { Slovak } from "flatpickr/dist/l10n/sk"
import { Czech } from "flatpickr/dist/l10n/cs"
import moment from "moment/moment"
import predefinedRanges from "../plugins/flatpickr-predefined-ranges"

// Connects to data-controller="flatpickr"
export default class extends Controller {
  static targets = ["input", "timeFrom", "timeTo"]

  static values = {
    locale: String,
    todayLabel: String,
    thisWeekLabel: String,
    thisMonthLabel: String,
    thisYearLabel: String,
    lastWeekLabel: String,
    lastMonthLabel: String,
    lastYearLabel: String,
  }

  connect() {
    if (!this.hasInputTarget) return
    if (!this.hasLocaleValue) return

    if (this.inputTarget.classList.contains("flatpickr-intialized")) return

    flatpickr(this.inputTarget, {
      altFormat: "d.m.Y",
      altInput: true,
      dateFormat: "Y-m-d",
      defaultDate: [this.timeFromTarget.value, this.timeToTarget.value],
      locale: this.localeValue === "cs" ? Czech : Slovak,
      mode: "range",
      plugins: [predefinedRanges()],
      rangesOnly: false,
      allowCustom: false,
      ranges: {
        [this.todayLabelValue]: [new Date(), new Date()],
        [this.thisWeekLabelValue]: [moment().startOf("isoWeek").toDate(), moment().endOf("isoWeek").toDate()],
        [this.thisMonthLabelValue]: [moment().startOf("month").toDate(), moment().endOf("month").toDate()],
        [this.thisYearLabelValue]: [moment().startOf("year").toDate(), moment().endOf("year").toDate()],
        [this.lastWeekLabelValue]: [
          moment().subtract(1, "week").startOf("isoWeek").toDate(),
          moment().subtract(1, "week").endOf("isoWeek").toDate(),
        ],
        [this.lastMonthLabelValue]: [
          moment().subtract(1, "month").startOf("month").toDate(),
          moment().subtract(1, "month").endOf("month").toDate(),
        ],
        [this.lastYearLabelValue]: [
          moment().subtract(1, "year").startOf("year").toDate(),
          moment().subtract(1, "year").endOf("year").toDate(),
        ],
      },
      onClose: (selectedDates) => {
        const dateFrom = selectedDates[0]
        const dateTo = selectedDates[1]

        const from = `${dateFrom.getFullYear()}-${dateFrom.getMonth() + 1}-${dateFrom.getDate()}`
        const to = `${dateTo.getFullYear()}-${dateTo.getMonth() + 1}-${dateTo.getDate()}`

        if (from === this.timeFromTarget.value && to === this.timeToTarget.value) return

        this.timeFromTarget.value = from
        this.timeToTarget.value = to

        this.element.requestSubmit()
      },
    })

    this.inputTarget.classList.add("flatpickr-intialized")
  }
}
