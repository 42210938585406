import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="countdown-timer"
export default class extends Controller {
  static values = {
    deadline: Number,
    countdown: {
      minutes: '00',
      hours: '00',
      days: '00'
    },
  }
  static targets = [
    'days',
    'hours',
    'minutes'
  ]

  connect() {
    // Define interval
    this.countdownInverval = setInterval(() => {
      const now = new Date().getTime();
      const timeLeft = (new Date(this.deadlineValue).getTime() - now) / 1000; //ms

      this.countdownValue = {
        minutes: Math.floor(timeLeft / 60) % 60,
        hours: Math.floor(timeLeft / (60 * 60)) % 24,
        days: Math.floor(timeLeft / (60 * 60 *24))
      }

    }, 1000);
  }

  // Deep watcher for countdown object - change displayed time
  countdownValueChanged(){
    this.daysTarget.innerHTML = this.countdownValue.days;
    this.hoursTarget.innerHTML = this.countdownValue.hours;
    this.minutesTarget.innerHTML = this.countdownValue.minutes;
  }

  disconnect(){
    // Stop interval on disconnect
    clearInterval(this.countdownInverval)
  }
}
