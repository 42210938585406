import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static targets = ["param", "trigger"]

  trigger() {
    let separator, href, params, query

    href = this.triggerTarget.getAttribute("href")

    params = this.paramTargets.filter((p) => {
      return p.value
    })

    query = this.serialize(params)

    if (query == "") return

    if (href.match(/\?/)) {
      separator = "&"
    } else {
      separator = "?"
    }

    Turbo.visit(`${href}${separator}${query}`, { action: "replace" })
  }

  handleKeypress(e) {
    if (e.keyCode == 13) {
      this.trigger()
    }
  }

  handleClick(e) {
    e.preventDefault()

    this.trigger()
  }

  serialize(params) {
    let obj = {}

    params.forEach((param) => {
      obj[param.getAttribute("name")] = param.value
    })

    return new URLSearchParams(obj).toString()
  }
}
