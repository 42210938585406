import { Controller } from "@hotwired/stimulus";

// TODO: Improve toggler controller, refactor to use values
export default class extends Controller {
  static targets = ["toggler", "toggleable"];

  toggle() {
    this.toggleableTarget.classList.toggle("hidden");

    let textContainer = this.togglerTarget.querySelector(".toggle-text");
    const showText = this.togglerTarget.getAttribute("data-b2b--widgets-show-text");
    const hideText = this.togglerTarget.getAttribute("data-b2b--widgets-hide-text");

    if (this.toggleableTarget.classList.contains("hidden")) {
      textContainer.textContent = showText;
    } else {
      textContainer.textContent = hideText;
    }
  }
}
