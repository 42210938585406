import { Controller } from "@hotwired/stimulus"
import Uppy from "@uppy/core"
import Dashboard from "@uppy/dashboard"
import Tus from "@uppy/tus"
import GoldenRetriever from "@uppy/golden-retriever"
import Czech from "@uppy/locales/lib/cs_CZ"
import Slovak from "@uppy/locales/lib/sk_SK"

import Vimeo from "../plugins/uppy-vimeo-plugin"

export default class extends Controller {
  static values = {
    debug: Boolean,
    prepareVideoUrl: String,
    processVideoUrl: String,
    maxNumberOfFiles: Number,
    singleFileMode: Boolean,
    courseId: String,
    locale: String,
  }

  static targets = ["dashboard", "vimeoId", "vimeoStatus", "button", "trigger"]

  connect() {
    this.uppy = new Uppy({
      debug: this.debugValue,
      autoProceed: true,
      locale: this.localeValue == "sk" ? Slovak : Czech,
      restrictions: {
        minNumberOfFiles: 1,
        maxNumberOfFiles: this.singleFileModeValue ? 1 : undefined,
        allowedFileTypes: ["video/*"],
      },
    })
      .use(Tus, {})
      .use(GoldenRetriever, { serviceWorker: true })
      .use(Vimeo, {
        prepareVideoUrl: this.prepareVideoUrlValue,
        processVideoUrl: this.processVideoUrlValue,
        courseId: this.courseIdValue,
      })

    // Used with chapter update, where we are replacing old vimeo_id with newly uploaded one
    if (this.hasVimeoIdTarget) {
      this.uppy.on("complete", (result) => {
        // Extract vimeoId from vimeo.uri
        const vimeoId = result.successful[0].vimeo.uri.split("/").pop()

        // Set vimeo_id hidden field value
        this.vimeoIdTarget.value = vimeoId
        this.vimeoStatusTarget.value = "uploaded"
      })
    }

    if (this.hasDashboardTarget) {
      this.uppy.use(Dashboard, {
        target: this.dashboardTarget,
        disableThumbnailGenerator: true,
        proudlyDisplayPoweredByUppy: false,
        showLinkToFileUploadResult: false,
        trigger: this.triggerTarget,
      })

      this.uppy.on("dashboard:modal-closed", () => {
        if (this.element.tagName === "FORM") {
          this.element.requestSubmit()
        } else {
          location.reload()
        }
      })
    }
  }
}
