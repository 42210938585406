import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="product-row"
export default class extends Controller {
  static values = {
    productCount: {type: Number, default: 0},
    productPrice: Number
  }

  static targets = [
    "summaryValue"
  ]

  setProductCount(event){
    this.productCountValue = parseInt(event.detail.count);
  }

  productCountValueChanged(){
    // Format number for better readability
    this.summaryValueTarget.textContent = new Intl.NumberFormat('sk-SK').format(this.productCountValue * this.productPriceValue);

    // Trigger custom event, so that other controllers might react to it
    dispatchEvent(new CustomEvent('productRowProductCountValueChanged', { detail: {count: this.productCountValue} }))
  }
}
