import Dialog from '@stimulus-components/dialog'

export default class extends Dialog {
  connect() {
    super.connect()
  }

  // Function to override on close.
  close() {
    this.dialogTarget.setAttribute("closing", "")

    Promise.all(this.dialogTarget.getAnimations().map((animation) => animation.finished)).then(() => {
      this.dialogTarget.removeAttribute("closing")
      this.dialogTarget.close()
    })

    let iframe = this.element.querySelector('iframe');

    if(iframe){
      this.pauseVimeoVideo(iframe);
    }
  }

  pauseVimeoVideo(vimeoIframe){
    const cachedSrcAttr = vimeoIframe.src;

    vimeoIframe.src = '';
    vimeoIframe.src = cachedSrcAttr;
  }
}
