import { Controller } from "@hotwired/stimulus"
import _debounce from "lodash/debounce"

// Connects to data-controller="collapse"
export default class extends Controller {

  static values = {
    open: false,
    maxHeight: Number,
    minHeight: 200,
    readMoreContent: String,
    readLessContent: String
  }

  static classes = [
    'expanded',
    'collapsed'
  ]

  static targets = [
    "collapse",
    "content",
    "trigger"
  ]

  connect() {
    // Initial calculations of content max height
    this.defineCollapseMaxHeight();
    // Initial set for trigger button text
    this.resolveTriggerButtonsContent();

    // Nasledne zmensime collapse na 0 vysku
    this.collapseTarget.style.maxHeight = `${this.minHeightValue}px`;

    // Mutation OBSERVER
    // tracks changes in DOM tree of the collapse content, then recounts max height
    // Create the observer + callback
    this.observer = new MutationObserver(function() {
        this.defineCollapseMaxHeight()
    }.bind(this));

    // observer settings
    this.observer.observe(
        this.contentTarget,
        { attributes: true, childList: true, characterData: true, subtree: true }
    );


    // Track all image elements inside collapse content and recount max height accordingly
    Promise.all(Array.from(this.contentTarget.querySelectorAll('img')).map(img => {
      if (img.complete)
        return Promise.resolve(img.naturalHeight !== 0);
      return new Promise(resolve => {
        img.addEventListener('load', () => resolve(true));
        img.addEventListener('error', () => resolve(false));
      });
    })).then(results => {
        // console.log("all images loaded")
        this.defineCollapseMaxHeight();
    });

    // Handle resize of content block - recount max height
    window.addEventListener('resize', _debounce(this.defineCollapseMaxHeight , 150).bind(this));
  }

  // Opens/Closes Collapse content
  toggle() {
    let el = this.collapseTarget;
    this.openValue = !this.openValue;

    el.classList.toggle('expanded', this.openValue)
    this.resolveTriggerButtonsContent();

    // OPEN
    // collapse
    if(this.openValue){
      el.style.maxHeight = this.maxHeightValue+'px';
    }

    // CLOSE
    // collapse
    else{
      el.style.maxHeight = this.minHeightValue+'px';
    }
  }

  // Resolve Trigger button content and visual
  resolveTriggerButtonsContent() {
    this.triggerTargets.forEach((el)=>{
      // Switch content
      if(this.openValue){
        el.innerHTML = this.readLessContentValue;
      }
      else{
        el.innerHTML = this.readMoreContentValue;
      }

      // Switch opened/closed classes
      if(this.hasCollapsedClass && this.hasExpandedClass){
        this.collapsedClasses.map(colCls => el.classList.toggle(colCls, !this.openValue));
        this.expandedClasses.map(expCls => el.classList.toggle(expCls, this.openValue));
      }

    })
  }

  // Define Collapsile content max-hegight
  defineCollapseMaxHeight() {
    this.maxHeightValue = this.contentTarget.offsetHeight;
  }
}
