import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="nested-field"
export default class extends Controller {
  static targets = ["destroyField"]
  static classes = ["opacity"]

  toggle(e) {
    e.preventDefault()

    if (this.destroyFieldTarget.value === "0") {
      this.destroyFieldTarget.value = "1"
      this.element.classList.add(this.opacityClass)
    } else {
      this.destroyFieldTarget.value = "0"
      this.element.classList.remove(this.opacityClass)
    }
  }

  remove() {
    this.element.remove()
  }
}
