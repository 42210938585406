import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="admin--locale-copy"
export default class extends Controller {
  static targets = ["input"]
  static values = {
    fromLocale: String,
    toLocale: String,
  }

  copy(fromLocale = "sk", toLocale = "cs") {
    const fromLocaleValues = this.inputTargets.filter((field) => field.dataset.language === fromLocale)
    const toLocaleValues = this.inputTargets.filter((field) => field.dataset.language === toLocale)

    toLocaleValues.forEach((field, index) => {
      if (field.value !== '' && field.value !== "<div class=\"trix-content\">\n  \n</div>") {
        return
      }

      field.value = fromLocaleValues[index].value
    })
  }

  copyFromSkToCsClick(e) {
    e.preventDefault()

    this.copy("sk","cs")
  }

  copyFromCsToSkClick(e) {
    e.preventDefault()

    this.copy("cs","sk")
  }
}
