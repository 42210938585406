import { Controller } from "@hotwired/stimulus"
import debounce from "lodash/debounce"

export default class extends Controller {
  static targets = ["form", "query", "searchResults", "sections"]

  initialize() {
    this.filter = debounce(this.filterChapters, 200).bind(this)
  }

  filterChapters() {
    if (this.queryTarget.value) {
      this.formTarget.requestSubmit()
      this.showFilter()
    } else {
      this.hideFilter()
    }
  }

  showFilter() {
    this.searchResultsTarget.hidden = false
    this.sectionsTarget.hidden = true
  }

  hideFilter() {
    this.searchResultsTarget.hidden = true
    this.sectionsTarget.hidden = false
  }
}
