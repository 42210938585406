import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["valueInput", "valueDisplay", "slider"]
  static values = { price: Number }

  connect() {
    this.initializeStyleProperties()
    this.update()
  }

  initializeStyleProperties() {
    this.sliderTarget.style.setProperty("--min", this.min)
    this.sliderTarget.style.setProperty("--max", this.max)
  }

  update() {
    // Update CSS variable --value to the current slider value
    this.sliderTarget.style.setProperty("--value", this.sliderValue)

    // Update value display
    this.valueDisplayTarget.innerText = this.sliderValue

    // Update main hidden field value
    this.valueInputTarget.value = parseInt(this.sliderValue) * 100
  }

  stepUp() {
    this.sliderTarget.stepUp()

    this.update()
  }

  stepDown() {
    this.sliderTarget.stepDown()

    this.update()
  }

  get sliderValue() {
    return this.sliderTarget.value
  }

  get min() {
    return this.sliderTarget.min == "" ? "0" : this.sliderTarget.min
  }

  get max() {
    return this.sliderTarget.max == "" ? "100" : this.sliderTarget.max
  }
}
