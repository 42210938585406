import { Controller } from "@hotwired/stimulus"
import GLightbox from "glightbox"

// Connects to data-controller="glightbox"
export default class extends Controller {
  connect() {
    console.log('lightbox')
    this.lightbox = GLightbox({
      autoplayVideos: true,
      touchNavigation: true,
      loop: true,
    });

  }

  disconnect(){
    
    this.lightbox = null;

  }
}
