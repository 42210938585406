import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tab-section"
export default class extends Controller {
  // Array of available tab ids - used in numbered navigation
  tabIds = [];

  static values = {
    activeTab: { type: Number, default: 0 },
  }

  static targets = [
    "navigationDesktop",
    "navigationMobile",
    "tab"
  ]

  initialize() {
    // Get list of all tabs IDs
    this.tabTargets.forEach(el => {
      this.tabIds.push(el.dataset.tab)
    })

    // If provided active tab # (activeTabValue) is higher than number of tabs, set active tab to max value
    if(this.activeTabValue > this.tabIds.length-1){
      this.activeTabValue = this.tabIds.length-1
    }

    // Add Event Listeners - desktop switch
    this.navigationDesktopTargets.forEach( el => {
      el.addEventListener("change", (evt) => {
        // Set active tab to chosen navigation
        this.activeTabValue = this.tabIds.indexOf(evt.target.dataset.tab)
      })
    })

    // Add Event Listener - mobile switch
    this.navigationMobileTarget.addEventListener("change", (evt) => {
      // Set active tab to chosen navigation
      this.activeTabValue = this.tabIds.indexOf(evt.target.value)
    })
  }

  activeTabValueChanged(){
    // Change active tab content
    this.tabTargets.forEach( el => {
      // Hide all tabs that don't match the selected tab
      el.classList.toggle('hidden', el.dataset.tab != this.tabIds[this.activeTabValue])
    })

    // Change active navigation - desktop
    this.navigationDesktopTargets.forEach( el => {
      el.checked = el.dataset.tab == this.tabIds[this.activeTabValue]
    })

    // Change active navigation - mobile
    this.navigationMobileTarget.value = this.tabIds[this.activeTabValue]
  }
}
