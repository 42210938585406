import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static values = {
    url: String,
    duration: Number,
  }

  connect() {
    setTimeout(() => {
      Turbo.visit(this.urlValue)
    }, this.durationValue || 7000)
  }
}
