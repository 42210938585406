import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="items-filter"
export default class extends Controller {
  static targets = ["clearQuery", "emptyState", "query", "item"]

  connect() {
    this.handleQueryChange()
  }

  isMatch(itemTarget) {
    if (this.queryValue.length === 0) return true

    return itemTarget.dataset.filterableBy.match(this.queryToRegex)
  }

  reset(e) {
    e.preventDefault()

    this.queryTarget.value = ""

    this.handleQueryChange()
  }

  handleQueryChange() {
    this.handleQueryButtonVisibility()
    this.handleItemsVisibility()
    this.handleEmptyStateVisibility()
  }

  handleQueryButtonVisibility() {
    if (this.queryValue) {
      this.clearQueryTarget.hidden = false
    } else {
      this.clearQueryTarget.hidden = true
    }
  }

  handleItemsVisibility() {
    this.itemTargets.forEach((itemTarget) => {
      if (this.isMatch(itemTarget)) {
        itemTarget.hidden = false
      } else {
        itemTarget.hidden = true
      }
    })
  }

  handleEmptyStateVisibility() {
    if (this.queryValue.length > 0 && this.matchedItems.length === 0) {
      this.emptyStateTarget.classList.remove("hidden")
      this.emptyStateTarget.classList.add("flex")
    } else {
      this.emptyStateTarget.classList.add("hidden")
      this.emptyStateTarget.classList.remove("flex")
    }
  }

  get queryValue() {
    return this.queryTarget.value.toLowerCase()
  }

  get queryToRegex() {
    return new RegExp(this.queryValue, "i")
  }

  get matchedItems() {
    return this.itemTargets.filter((itemTarget) => {
      return this.isMatch(itemTarget)
    })
  }
}
