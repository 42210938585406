import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="scroll-into-view"
export default class extends Controller {
  connect() {
    // Timeout handles async requests, when data is loaded after the page is rendered
    // 250ms is a good value for most cases
    setTimeout(() => {
      this.element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' })
    }, 250)
  }
}
