import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="student--chapter-navigation"
export default class extends Controller {
  static targets = ["tabNavLink"]

  handleClick(e) {
    this.tabNavLinkTargets.forEach((linkEl) => {
      linkEl.classList.remove("active")
    })

    e.currentTarget.classList.add("active")
  }
}
