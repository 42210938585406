import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content", "button"]
  static values = {
    initialHeight: Number,
    originalHeight: Number,
    step: Number,
  }

  connect() {
    const contentTarget = this.contentTarget
    const buttonTarget = this.buttonTarget

    // Store original height
    this.originalHeightValue = contentTarget.offsetHeight

    // Make it small if needed
    if (this.originalHeightValue > this.initialHeightValue) {
      contentTarget.style.height = this.initialHeightValue + "px"
    } else {
      buttonTarget.style.display = "none"
    }
  }

  expandContent(e) {
    e.preventDefault()

    const contentTarget = this.contentTarget
    const buttonTarget = this.buttonTarget
    const originalHeight = this.originalHeightValue
    const step = this.stepValue

    // Make it taller
    if (contentTarget.offsetHeight + step > originalHeight) {
      contentTarget.style.height = originalHeight + "px"
      buttonTarget.style.display = "none"
    } else {
      contentTarget.style.height = contentTarget.offsetHeight + step + "px"
    }
  }
}
