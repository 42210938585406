import { Controller } from "@hotwired/stimulus"
import tippy from "tippy.js"

export default class extends Controller {
  static values = {
    text: String,
    placement: String,
  }

  connect() {
    tippy(this.element, {
      content: this.textValue,
      placement: this.placementValue,
      animation: "fade",
      allowHTML: true,
    })
  }
}
