import { Controller } from "@hotwired/stimulus"
import _throttle from "lodash/throttle"

// Connects to data-controller="carousel"
export default class extends Controller {
  // Local vars
  responsiveSkip = 0
  nothingToScroll = false
  childrenInViewport = 0

  // External+reactive vars
  static values = {
    skip: Number,
    activeDot: Number,
    numberOfDots: Number,
    isAtStart: Boolean,
    isAtEnd: Boolean
  }

  // Targets
  static targets = [
    "slider",
    "pagination",
    "next",
    "previous"
  ]

  connect() {
    // Abort initialization if slider doesn't have any children - EDGE CASE
    if (!this.sliderTarget.firstElementChild) return

    //Event listeners
    this.sliderTarget.addEventListener('scroll', _throttle(()=>{
      this.carouselChecks();
    }, 100))

    // Intersection observer - track visible children - add/remove visible class
    // Intersection Observer Callback fn
    const observerCB = entries => {
      entries.forEach(entry => {
        entry.target.classList.toggle("visible", entry.isIntersecting);
      })
    };
    // Intersection Observer Opts
    const observerOptions = {
      root: this.sliderTarget,
      rootMargin: '0px',
      threshold: 0.5
    };
    // Create Intersection Observer
    const observer = new IntersectionObserver(observerCB, observerOptions);
    // Attach observer to DOM instances
    const observerTargets = this.sliderTarget.querySelectorAll('.slide');
    observerTargets.forEach(target => observer.observe(target));

    // Resize Observer
    //Observer callback fn
    const resizeObserverCb = entries => {
      this.carouselChecks();
    }

    const resizeObserver = new ResizeObserver(resizeObserverCb);
    resizeObserver.observe(this.sliderTarget)

    //OnLoad setup
    this.carouselChecks();
  }

  next() {
    this.to((current, offset) => current + (offset * this.responsiveSkip))
  }

  prev() {
    this.to((current, offset) => current - (offset * this.responsiveSkip))
  }

  jumpTo(event) {
    this.to((current, offset) => event.params.index * (offset * this.responsiveSkip))
  }

  to(strategy) {
    let slider = this.sliderTarget

    if (!slider) return;

    let current = slider.scrollLeft
    let offset = slider.firstElementChild.clientWidth

    slider.scrollTo({
      left: strategy(current, offset),
      behavior: 'smooth'
    })
  }

  // Provides basic carousel checks - is Carousel at edge, how many children are visible, resolves active dot
  carouselChecks() {
    const slider = this.sliderTarget
    if (!slider) return;

    const current = slider.scrollLeft;
    const sliderWidth = slider.clientWidth;
    const offset = slider.firstElementChild.clientWidth;
    const nrOfChildren = slider.children.length;

    // Determines if carousel is at its edges or if its even scrollable
    this.isAtStartValue = current == 0 ? true : false;
    this.isAtEndValue = slider.clientWidth > slider.scrollWidth - (current + 10) ? true : false; //10 je tam pridane len ako buffer kvoli nespravnemu zaokruhlovaniu cisiel JS
    this.nothingToScroll = slider.scrollWidth > slider.clientWidth ? false : true;

    // Determines the number of visible slides
    this.childrenInViewport = Math.round(sliderWidth / offset);

    // Resolves if the skip is fixed number or if the carousel should skip number of slides based on visible slides (responsive option)
    const resolveSkip = () => {
      if (this.skipValue === 0) {
        return this.childrenInViewport
      } else {
        return this.skipValue
      }
    }

    // If there are less elements in viewport than it is set to skip at any given directional step(left/right) than set the responsive skip to that of the viewport width
    if (resolveSkip() > this.childrenInViewport) {
      this.responsiveSkip = this.childrenInViewport;
    } else {
      this.responsiveSkip = resolveSkip();
    }

    // Determines number of navigation dots in carousel
    // nrOfChildren - this.childrenInViewport + 1
    const isThereRemainder = (nrOfChildren - this.childrenInViewport) % this.responsiveSkip ? 1 : 0;
    this.numberOfDotsValue = Math.floor((nrOfChildren - this.childrenInViewport) / this.responsiveSkip) + 1 + isThereRemainder;

    //Resolves active page within carousel
    this.activeDotValue = Math.ceil(current / (offset * this.responsiveSkip));
  }

  // Watcher for changes in number of Pagination dots - re-renders pagination
  numberOfDotsValueChanged() {
    if (this.hasPaginationTarget) {
      // Get the dot template
      const dotTemplate = this.element.querySelector("#temp_navigation_dot");

      // First we clear the pagination container, so that it can be redrawn
      this.paginationTarget.innerHTML = "";

      // than we render new number of dots based on numberOfDotsValue
      for (let index = 0; index < this.numberOfDotsValue; index++) {
        let dotClone = dotTemplate.content.firstElementChild.cloneNode(true);
        dotClone.dataset.carouselIndexParam = index;
        this.paginationTarget.appendChild(dotClone)
      }

      // Render current active DOT
      this.activeDotValueChanged();
    }
  }

  // Watcher for changes in active dot - renders active dot in pagination
  activeDotValueChanged() {
    if (this.hasPaginationTarget && this.paginationTarget.childNodes.length) {
      this.paginationTarget.childNodes.forEach(el => {
        el.classList.toggle("bg-corporate", el.dataset.carouselIndexParam == this.activeDotValue)
        el.classList.toggle("w-8", el.dataset.carouselIndexParam == this.activeDotValue)

        el.classList.toggle("bg-slate-300", el.dataset.carouselIndexParam != this.activeDotValue)
      })
    }
  }

  // Watcher for if its carousel at its starting edge - disables the previous navigation button
  isAtStartValueChanged() {
    if (this.hasPreviousTarget) {
      this.previousTarget.disabled = this.isAtStartValue;
    }
  }

  // Watcher for if its carousel at its ending edge - disables the next navigation button
  isAtEndValueChanged() {
    if (this.hasNextTarget) {
      this.nextTarget.disabled = this.isAtEndValue;
    }
  }

}
