import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="price-field"
export default class extends Controller {
  static targets = ["originalPrice", "calculatedPrice"]

  connect() {
    if (!this.hasOriginalPriceTarget) console.error("Missing originalPrice target.")
    if (!this.hasCalculatedPriceTarget) console.error("Missing calculatedPrice target.")

    this.originalPriceTarget.value = Math.round(this.calculatedPriceTarget.value / 100)
  }

  calculate() {
    this.calculatedPriceTarget.value = Math.round(this.originalPriceTarget.value * 100)
  }
}
