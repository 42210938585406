import { Controller } from "@hotwired/stimulus"
const _debounce = require("lodash/debounce")

// Connects to data-controller="invite-users"
export default class extends Controller {
  static values = {
    availableLicenses: Number,
    overdrawAdresses: Boolean,
    trialLicense: Boolean,
    preselectedRole: String,
    remainingLicenses: Number,
  }

  static targets = [
    "availableLicenses",
    "buttonSubmitInviteUsers",
    "formBuyMoreLicenses",
    "formInviteUsers",
    "licensesStatus",
    "licensesStatusIcon",
    "overdrawError",
    "renderedEmailAddresses",
    "totalLicenses",
    "usersInput",
    "validAddresses",
  ]

  initialize() {
    // Debounce input action to prevent performance issues
    this.input = _debounce(this.input, 300).bind(this)
  }

  connect() {
    if (this.trialLicenseValue) {
      this.overdrawAdressesValue = false
    } else {
      // Set Initial value for adresses overdraw based on number of available licenses
      this.overdrawAdressesValue = this.availableLicensesValue ? false : true

      // Set initial licesnses status
      this.remainingLicensesValue = this.availableLicensesValue
      this.totalLicensesTarget.textContent = this.availableLicensesValue
      this.availableLicensesTarget.textContent = this.availableLicensesValue
    }
  }

  input() {
    // 1, Search for email addresses in input content
    const inputVal = this.usersInputTarget.value
    const regex = /([a-zA-Z0-9.+_-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/g
    const match = inputVal.match(regex)
    const matchedUnique = [...new Set(match)]

    // 2, If there are matches, than change the displayed status of available licenses
    // if there are none, than reset the status
    if (matchedUnique && matchedUnique.length) {
      this.remainingLicensesValue = this.availableLicensesValue - matchedUnique.length

      let validAddresses;
      if (this.trialLicenseValue) {
        validAddresses = matchedUnique
      } else {
        validAddresses = matchedUnique.slice(0, this.availableLicensesValue)
      }
      this.validAddressesTarget.value = JSON.stringify(validAddresses)

      // if the sum of licenses is positive, than display status, otherwise display 0 and overdraw warning
      if (this.remainingLicensesValue >= 0 || this.trialLicenseValue) {
        this.availableLicensesTarget.textContent = this.remainingLicensesValue
        this.overdrawAdressesValue = false
      } else {
        this.availableLicensesTarget.textContent = 0
        this.overdrawAdressesValue = true
      }
    } else {
      this.remainingLicensesValue = this.availableLicensesValue
      // Show initial licenses status
      this.availableLicensesTarget.textContent = this.availableLicensesValue
      this.overdrawAdressesValue = false
    }
    if (this.trialLicenseValue) {
      this.availableLicensesTarget.textContent = '∞'
    }
  }

  overdrawAdressesValueChanged(current, _old) {
    // Disable/Enable invite-users form to prevent accidental submission
    if (current) {
      this.formInviteUsersTarget.setAttribute("onsubmit", "return false")
    } else {
      this.formInviteUsersTarget.removeAttribute("onsubmit")
    }

    // Change color of the license status indicator
    this.licensesStatusTarget.classList.toggle("bg-faded-red", current)
    this.licensesStatusTarget.classList.toggle("bg-skillmea-green-200", !current)

    this.licensesStatusIconTarget.classList.toggle("bg-vivid-raspberry", current)
    this.licensesStatusIconTarget.classList.toggle("bg-corporate", !current)

    // Show/Hide the overdraw error
    this.overdrawErrorTarget.classList.toggle("hidden", !current)

    // Show/hide Submit button of the invite users form
    this.buttonSubmitInviteUsersTarget.classList.toggle("hidden", current)

    // Show/hide form for ordering additional licenses
    this.formBuyMoreLicensesTarget.classList.toggle("hidden", !current)
  }

  remainingLicensesValueChanged(current, previous) {
    if (previous != current) {
      // Play animation
      window.anime({
        targets: this.element.querySelector("#license_status"),
        keyframes: [{ scale: 1.6 }, { scale: 1 }],
        duration: 150,
        easing: "easeOutQuad",
      })
    }

    if (current < 0) {
      // Trigger custom event, so that other controllers might react to it
      dispatchEvent(
        new CustomEvent("inviteUsersInvalidAddressesCountChanged", { detail: { count: Math.abs(current) } })
      )
    }
  }
}
