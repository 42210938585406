import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="nested-fields"
export default class extends Controller {
  static targets = ["template", "container"]

  connect() {
    if (!this.hasTemplateTarget) console.error("Missing template target.")
    if (!this.hasContainerTarget) console.error("Missing container target.")
  }

  add(e) {
    e.preventDefault()

    const id = this.epochTime
    const elementClone = this.templateTarget.content.cloneNode(true)
    const elementHTML = elementClone.firstElementChild.outerHTML
    const sanitizedHTML = elementHTML.replaceAll("REPLACE", id)

    this.containerTarget.insertAdjacentHTML("beforeend", sanitizedHTML)
  }

  get epochTime() {
    return new Date().valueOf()
  }
}
