import moment from "moment/moment"

export default function () {
  return function (fp) {
    let rangesNav = document.createElement("ul")
    rangesNav.className = "nav flex-column p-2 flatpickr-predefined-ranges"

    const pluginData = {
      ranges: typeof fp.config.ranges !== "undefined" ? fp.config.ranges : {},
      rangesOnly: typeof fp.config.rangesOnly === "undefined" || fp.config.rangesOnly,
      rangesNav: rangesNav,
      rangesButtons: {},
    }

    /**
     * @param {string} label
     * @returns HTML Element
     */
    const addRangeButton = function (label) {
      let button = document.createElement("button")
      button.type = "button"
      button.className = "nav-link btn btn-link"
      button.innerText = label

      pluginData.rangesButtons[label] = button

      let item = document.createElement("li")
      item.className = "nav-item d-grid"

      item.appendChild(pluginData.rangesButtons[label])

      pluginData.rangesNav.appendChild(item)

      return pluginData.rangesButtons[label]
    }

    /**
     * Loop the ranges and check for one that matches the selected dates, adding
     * an active class to its corresponding button.
     *
     * If there are selected dates and a range is not matched, check for a custom
     * range button and set it to active.
     *
     * If there are no selected dates or a range is not matched, check if the
     * rangeOnly option is true and if so hide the calendar.
     *
     * @param {Array} selectedDates
     */
    const selectActiveRangeButton = function (selectedDates) {
      // let isPredefinedRange = false
      let current = pluginData.rangesNav.querySelector(".active")

      if (current) {
        current.classList.remove("active")
      }

      if (selectedDates.length > 0) {
        let startDate = moment(selectedDates[0])
        let endDate = selectedDates.length > 1 ? moment(selectedDates[1]) : startDate
        for (const [label, range] of Object.entries(pluginData.ranges)) {
          if (startDate.isSame(moment(range[0]), "day") && endDate.isSame(moment(range[1]), "day")) {
            pluginData.rangesButtons[label].classList.add("active")
            // isPredefinedRange = true
            break
          }
        }
      }

      // if (
      //   selectedDates.length > 0 && !isPredefinedRange
      // ) {
      //   fp.calendarContainer.classList.add("flatpickr-predefined-ranges-only")
      // }
    }

    return {
      /**
       * Loop the ranges and add buttons for each range which a click handler to set the date.
       * Also adds a custom range button if the rangesAllowCustom option is set to true.
       */
      onReady(selectedDates) {
        for (const [label, range] of Object.entries(pluginData.ranges)) {
          addRangeButton(label).addEventListener("click", function () {
            let start = moment(range[0]).toDate()
            let end = moment(range[1]).toDate()

            if (!start) {
              fp.clear()
            } else {
              fp.setDate([start, end], true)
            }

            fp.close()
          })
        }

        if (pluginData.rangesNav.children.length > 0) {
          fp.calendarContainer.prepend(pluginData.rangesNav)
          fp.calendarContainer.classList.add("flatpickr-has-predefined-ranges")
          // make sure the right range button is active for the default value
          selectActiveRangeButton(selectedDates)
        }
      },

      /**
       * Make sure the right range button is active when a value is manually entered
       *
       * @param {Array} selectedDates
       */
      onValueUpdate(selectedDates) {
        selectActiveRangeButton(selectedDates)
      },
    }
  }
}
