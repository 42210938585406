import { Controller } from "@hotwired/stimulus"
import fx from "money"

export default class extends Controller {
  static values = {
    baseCurrency: String,
  }

  static targets = [
    "price",
    "priceEur",
    "priceEurInt",
    "priceCzk",
    "priceCzkInt",
  ]

  initialize() {
    this.setupPriceFields(this.baseCurrencyValue)
    this.setupConversion(this.baseCurrencyValue)
    this.setupPrices()
  }

  updatePrices(event) {
    this.priceTargets.forEach((target) => {
      // Strip invalid characters, commas and whitespaces out
      this.ensureValidNumberValue(target)

      // We want to update only the readonly field, since read/write field takes value from user
      if (!target.readOnly) return

      target.value = Math.round(fx.convert(event.target.value))
    })

    this.priceEurIntTarget.value = this.priceEurTarget.value * 100
    this.priceCzkIntTarget.value = this.priceCzkTarget.value * 100
  }

  setupPriceFields(baseCurrency) {
    if (baseCurrency == "EUR") {
      this.priceCzkTarget.setAttribute("readonly", true)
    } else {
      this.priceEurTarget.setAttribute("readonly", true)
    }
  }

  setupPrices() {
    this.priceEurTarget.value = this.priceEurIntTarget.value / 100.0
    this.priceCzkTarget.value = this.priceCzkIntTarget.value / 100.0
  }

  setupConversion(baseCurrency) {
    fx.base = baseCurrency

    if (baseCurrency == "EUR") {
      fx.settings = {
        from: "EUR",
        to: "CZK",
      }

      fx.rates = {
        EUR: 1,
        CZK: 25.4,
      }
    } else {
      fx.settings = {
        from: "CZK",
        to: "EUR",
      }

      fx.rates = {
        CZK: 1,
        EUR: 0.039,
      }
    }
  }

  ensureIntegerValues() {
    this.priceTargets.forEach((target) => {
      if (target.value.indexOf(".")) {
        target.value = Math.round(target.value * 100)
      }
    })
  }

  ensureValidNumberValue(target) {
    target.value = target.value.replace(
      /[`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/a-z\s]/gi,
      ""
    )
  }
}
