import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="image-attachments"
export default class extends Controller {
  static targets = ["previewContainer", "imagePreview", "input", "label"]

  connect() {
    if (!this.hasImagePreviewTarget) {
      console.error("Missing imagePreviewTarget element!")
    }
  }

  updatePreview(evt) {
    const reader = new FileReader()

    reader.onload = (e) => {
      this.imagePreviewTarget.setAttribute("src", e.target.result)
    }

    reader.readAsDataURL(evt.target.files[0])

    if (this.hasPreviewContainerTarget) {
      this.previewContainerTarget.classList.remove("hidden")
    }

    if (this.hasLabelTarget) {
      this.labelTarget.classList.add("hidden")
    }
  }

  reset() {
    if (this.hasPreviewContainerTarget) {
      this.previewContainerTarget.classList.add("hidden")
    }

    if (this.hasLabelTarget) {
      this.labelTarget.classList.remove("hidden")
    }

    this.inputTarget.value = ""
  }
}
