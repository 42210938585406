import { Controller } from "@hotwired/stimulus"
import Chart from "chart.js/auto"

export default class extends Controller {
  static values = { type: String, data: Array }

  connect() {
    var myChart = new Chart(this.element, {
      type: this.typeValue,
      data: {
        datasets: [
          {
            label: "",
            data: this.dataValue,
            backgroundColor: ["#bbe0e6", "#e3f6f9"],
            borderColor: ["transparent"],
          },
        ],
      },
      options: {
        width: 200,
      },
    })
  }
}
