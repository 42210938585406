import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["query", "item"]

  toggleInput(event) {
    const element = this.queryTarget
    element.classList.toggle("d-none")
    element.focus()

    event.preventDefault()
  }

  search() {
    const element = this.queryTarget
    const query = element.value

    // Clear initial state
    const sections = document.querySelectorAll("[data-expand]")
    for (let section of sections) {
      section.removeAttribute("data-expand")
      section.classList.remove("show")
      section.previousElementSibling.parentElement.classList.add("d-none")
    }

    // Filter items
    const items = this.itemTargets
    for (let item of items) {
      let title = item.dataset.title
      let sectionDomId = item.dataset.sectionDomId
      let section = document.getElementById(sectionDomId)

      if (title.search(new RegExp(query, "i")) < 0) {
        item.classList.add("d-none")
      } else {
        item.classList.remove("d-none")
        section.dataset.expand = "true"
      }
    }

    // Expand sections with results
    const sectionsToExpand = document.querySelectorAll("[data-expand=true]")
    for (let section of sectionsToExpand) {
      section.classList.add("show")
      section.previousElementSibling.parentElement.classList.remove("d-none")
    }
  }
}
