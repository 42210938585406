import { Controller } from "@hotwired/stimulus"
const _debounce = require("lodash/debounce")

// Connects to data-controller="invitation-form"
export default class extends Controller {
  static targets = ["userInput", "validAddresses"]

  initialize() {
    this.input = _debounce(this.input, 300).bind(this)
  }

  connect() {
    console.log("Hello, Stimulus!", this.element)
  }

  input() {
    const inputVal = this.userInputTarget.value
    const regex = /([a-zA-Z0-9.+_-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/g
    const match = inputVal.match(regex)
    const matchedUnique = [...new Set(match)]

    this.validAddressesTarget.value = JSON.stringify(matchedUnique)
  }
}
