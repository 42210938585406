import { Controller } from '@hotwired/stimulus';
import tippy from 'tippy.js';

export default class extends Controller {
  copy(event) {
    event.preventDefault();

    const triggerElement = event.currentTarget;

    const linkToCopy = this.data.get('link');

    const tooltipSuccess = this.data.get('tooltipSuccess');
    const tooltipError = this.data.get('tooltipError');

    navigator.clipboard.writeText(linkToCopy).then(() => {
      this.showTooltip(triggerElement, tooltipSuccess);
    }, () => {
      this.showTooltip(triggerElement, tooltipError);
    });
  }

  showTooltip(element, tooltipText) {
    tippy(element, {
      content: tooltipText,
      trigger: 'manual',
      onShow(instance) {
        setTimeout(() => {
          instance.hide();
        }, 2000);
      },
    }).show();
  }
}
