import { Controller } from "@hotwired/stimulus"
//import("@uppy/golden-retriever/lib/ServiceWorker")

export default class extends Controller {
  connect() {
    if (navigator.serviceWorker) {
      if (navigator.serviceWorker.controller) {
        console.info("Service worker already running..")
        // If the service worker is already running, skip to state change
        this.stateChange()
      } else {
        // Register the service worker, and wait for it to become active
        navigator.serviceWorker.register("/service-worker.js", { scope: "./" }).then(function (reg) {
          console.log("[Companion]", "Service worker registered!")
        })
        navigator.serviceWorker.addEventListener("controllerchange", this.controllerChange.bind(this))
      }
    } else {
      console.info("Service worker not supported.")
    }
  }

  controllerChange(event) {
    navigator.serviceWorker.controller.addEventListener("statechange", this.stateChange.bind(this))
  }

  stateChange() {
    // perform any visual manipulations here
  }
}
