import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="other-field"
export default class extends Controller {
  static targets = ["dropdown", "input"]

  connect() {
    this.resolveInputVisibility()
  }

  resolveInputVisibility() {
    if (this.dropdownTarget.value === "other") {
      this.inputTarget.classList.remove("hidden")
    } else {
      this.inputTarget.classList.add("hidden")
    }
  }
}
