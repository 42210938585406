import ReadMore from "@stimulus-components/read-more"

// Connects to data-controller="read-more"
export default class extends ReadMore {
  static targets = ["readMore"]

  connect() {
    super.connect()

    const scrollHeight = this.contentTarget.scrollHeight
    const clientHeight = this.contentTarget.clientHeight

    if (scrollHeight > clientHeight) {
      this.readMoreTarget.classList.remove("hidden")
    } else {
      this.readMoreTarget.classList.add("hidden")
    }
  }
}
